import * as styleVars from '@common/styles/constants'

import { forwardRef } from 'react'
import Select, {
  GroupBase,
  Props as ReactSelectProps,
  createFilter,
} from 'react-select'
import { TableSelectWrapper, commonStyles } from './styles'
import { capitalizeFirstLetter, classNames } from '@common/utils/helpers'
// src
import { useCombinedRefs, useOverflowDetect } from '@common/utils/use-hooks'

import CreatableSelect from 'react-select/creatable'
import DropdownIndicator from './components/dropdown-indicator'
import { IFilterConfig } from '.'
import { ISelectOption } from '../multi-select'
import { PqSelectProps } from './types'
import customOption from './components/option-custom'
import toolTipSingleValue from './components/single-value-tooltip'

const handleSelectStyling = (styleParams): any => {
  const tableStyle = {
    ...commonStyles,
    container: (styles, { selectProps }) => ({
      ...styles,
      // remove pointer events when isOverflowed
      // to display tooltip
      // @todo fix as this doesn't seem to work actually
      pointerEvents: selectProps.isOverflowed ? 'unset' : 'normal',
    }),
    control: (_, { selectProps }) => ({
      width: selectProps.width,
    }),
    placeholder: (styles, { isFocused }) => ({
      ...styles,
      display: isFocused ? 'none' : 'block',
      color: '#8a91a5',
      opacity: '.5',
      // select value font-size seems to be different for 13
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.54',
      letterSpacing: 'normal',
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0',
      color: '#0d1331',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      width: styleParams.menuWidth ?? '182px',
      zIndex: '2',
      left: '-15px',
      boxShadow: '0 2px 16px 0 rgba(203, 208, 223, 0.4)',
      margin: '0',
      padding: '0',
      top: '40px',
    }),
    menuList: (styles) => ({
      ...styles,
      margin: '0',
      padding: '0',
      backgroundColor: styleVars.white,
      opacity: 1,
    }),
    input: (styles) => ({
      ...styles,
      fontWeight: 'normal',
    }),
    singleValue: (styles, { selectProps }) => ({
      ...styles,
      // select value font-size seems to be different for 13
      display: selectProps.menuIsOpen ? 'none' : 'block',
      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.54',
      letterSpacing: 'normal',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '105px',
      textOverflow: 'ellipsis',
      '::WebkitScrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
    }),
    option: (styles, state) => {
      return {
        ...styles,
        ...commonStyles.option(styles),
        fontWeight: 500,
        fontSize: 13,
        margin: '0',
        width: '100%',
        cursor: 'pointer',
        color: state.isFocused
          ? `${styleVars.primaryBlue} !important`
          : 'inherit',
        ':hover': {
          color: `${styleVars.primaryBlue} !important`,
        },
        ':active': {
          backgroundColor: '#DEEBFF',
        },
      }
    },
  }

  return tableStyle
}

export interface PqTableSelectProps {
  isCreatable?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  borderRadius?: string
  inputMarginLeft?: number
  isTableSelect?: boolean
  menuWidth?: string
  showPlaceholderMode?: boolean
  id?: string
  onChange?: (e: any) => void
  onFocus?: () => void
  name?: string
  width?: string
  className?: string
  disabled?: boolean
  caption?: string
  placeholder?: string
  errorMessage?: string
  isOverflowed?: boolean
  options?: { value: string | number; label: string | number }[]
}

export type IPqTableSelectProps = PqSelectProps &
  Partial<ReactSelectProps<ISelectOption, boolean, GroupBase<ISelectOption>>> &
  PqTableSelectProps

const PqTableSelect = forwardRef((props: IPqTableSelectProps, forwardedRef) => {
  const {
    isCreatable,
    id,
    name,
    className,
    menuWidth,
    placeholder,
    options,
    ...rest
  } = props

  const classes = classNames('pq-select', className || '')
  const customStyle = handleSelectStyling({ menuWidth })

  const [isOverflowed, overflowRef] = useOverflowDetect()

  const filterConfig: IFilterConfig = {
    ignoreCase: true,
    matchFrom: 'start',
  }

  const combinedRefs = useCombinedRefs(forwardedRef)

  if (isCreatable) {
    return (
      <TableSelectWrapper>
        <div data-cy="form-creatable-select">
          <CreatableSelect
            id={id}
            menuPlacement="auto"
            className={classes}
            styles={customStyle}
            placeholder={placeholder}
            name={name}
            options={options}
            isOverflowed={isOverflowed}
            instanceId={name}
            components={{
              SingleValue: (singleValueProps) => {
                return toolTipSingleValue(
                  singleValueProps,
                  overflowRef,
                  isOverflowed,
                )
              },
              Option: customOption,
            }}
            ref={combinedRefs}
            filterOption={createFilter(filterConfig)}
            formatCreateLabel={(input) => capitalizeFirstLetter(input)}
            {...rest}
          />
        </div>
      </TableSelectWrapper>
    )
  }

  // this one is a work in progress
  return (
    <TableSelectWrapper>
      <Select
        menuPlacement="auto"
        id={id}
        className={classes}
        classNamePrefix="pq-select"
        name={name}
        components={{
          DropdownIndicator,
          SingleValue: (singleValueProps) => {
            return toolTipSingleValue(singleValueProps)
          },
          Option: customOption,
        }}
        ref={combinedRefs}
        options={options}
        instanceId={name}
        placeholder={placeholder}
        isDisabled={false}
        isSearchable={false}
        {...rest}
      />
    </TableSelectWrapper>
  )
})

export default PqTableSelect
