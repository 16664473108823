export enum keyCodes {
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  ENTER = 'Enter',
  SHIFT = 'Shift',
  CTRL = 'Ctrl',
  ALT = 'Alt',
  PAUSE = 'Pause',
  CAPS_LOCK = 'CapsLock',
  ESCAPE = 'Escape',
  SPACE = 'Space',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',
  END = 'End',
  HOME = 'Home',
  LEFT_ARROW = 'LeftArrow',
  UP_ARROW = 'UpArrow',
  RIGHT_ARROW = 'RightArrow',
  DOWN_ARROW = 'DownArrow',
  INSERT = 'Insert',
  DELETE = 'Delete',
  DIGIT_0 = 'Digit0',
  DIGIT_1 = 'Digit1',
  DIGIT_2 = 'Digit2',
  DIGIT_3 = 'Digit3',
  DIGIT_4 = 'Digit4',
  DIGIT_5 = 'Digit5',
  DIGIT_6 = 'Digit6',
  DIGIT_7 = 'Digit7',
  DIGIT_8 = 'Digit8',
  DIGIT_9 = 'Digit9',
  KEY_A = 'KeyA',
  KEY_B = 'KeyB',
  KEY_C = 'KeyC',
  KEY_D = 'KeyD',
  KEY_E = 'KeyE',
  KEY_F = 'KeyF',
  KEY_G = 'KeyG',
  KEY_H = 'KeyH',
  KEY_I = 'KeyI',
  KEY_J = 'KeyJ',
  KEY_K = 'KeyK',
  KEY_L = 'KeyL',
  KEY_M = 'KeyM',
  KEY_N = 'KeyN',
  KEY_O = 'KeyO',
  KEY_P = 'KeyP',
  KEY_Q = 'KeyQ',
  KEY_R = 'KeyR',
  KEY_S = 'KeyS',
  KEY_T = 'KeyT',
  KEY_U = 'KeyU',
  KEY_V = 'KeyV',
  KEY_W = 'KeyW',
  KEY_X = 'KeyX',
  KEY_Y = 'KeyY',
  KEY_Z = 'KeyZ',
  LEFT_META = 'LeftMeta',
  RIGHT_META = 'RightMeta',
  SELECT = 'Select',
  NUMPAD_0 = 'NUMPAD_0',
  NUMPAD_1 = 'NUMPAD_1',
  NUMPAD_2 = 'NUMPAD_2',
  NUMPAD_3 = 'NUMPAD_3',
  NUMPAD_4 = 'NUMPAD_4',
  NUMPAD_5 = 'NUMPAD_5',
  NUMPAD_6 = 'NUMPAD_6',
  NUMPAD_7 = 'NUMPAD_7',
  NUMPAD_8 = 'NUMPAD_8',
  NUMPAD_9 = 'NUMPAD_9',
  MULTIPLY = 'Multiply',
  ADD = 'Add',
  SUBTRACT = 'Subtract',
  DECIMAL = 'Decimal',
  DIVIDE = 'Divide',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',
  NUM_LOCK = 'NumLock',
  SCROLL_LOCK = 'ScrollLock',
  SEMICOLON = 'SemiColon',
  EQUALS = 'EQUALS',
  COMMA = 'COMMA',
  DASH = 'DASH',
  PERIOD = 'PERIOD',
  FORWARD_SLASH = 'FORWARD_SLASH',
  GRAVE_ACCENT = 'GRAVE_ACCENT',
  OPEN_BRACKET = 'OPEN_BRACKET',
  BACK_SLASH = 'BACK_SLASH',
  CLOSE_BRACKET = 'CLOSE_BRACKET',
  SINGLE_QUOTE = 'SINGLE_QUOTE',
}

export enum keyCodesMap {
  BACKSPACE = 8,
  TAB = 9,
  ENTER = 13,
  SHIFT = 16,
  CTRL = 17,
  ALT = 18,
  PAUSE = 19,
  CAPS_LOCK = 20,
  ESCAPE = 27,
  SPACE = 32,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  END = 35,
  HOME = 36,
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
  INSERT = 45,
  DELETE = 46,
  KEY_0 = 48,
  KEY_1 = 49,
  KEY_2 = 50,
  KEY_3 = 51,
  KEY_4 = 52,
  KEY_5 = 53,
  KEY_6 = 54,
  KEY_7 = 55,
  KEY_8 = 56,
  KEY_9 = 57,
  KEY_A = 65,
  KEY_B = 66,
  KEY_C = 67,
  KEY_D = 68,
  KEY_E = 69,
  KEY_F = 70,
  KEY_G = 71,
  KEY_H = 72,
  KEY_I = 73,
  KEY_J = 74,
  KEY_K = 75,
  KEY_L = 76,
  KEY_M = 77,
  KEY_N = 78,
  KEY_O = 79,
  KEY_P = 80,
  KEY_Q = 81,
  KEY_R = 82,
  KEY_S = 83,
  KEY_T = 84,
  KEY_U = 85,
  KEY_V = 86,
  KEY_W = 87,
  KEY_X = 88,
  KEY_Y = 89,
  KEY_Z = 90,
  LEFT_META = 91,
  RIGHT_META = 92,
  SELECT = 93,
  NUMPAD_0 = 96,
  NUMPAD_1 = 97,
  NUMPAD_2 = 98,
  NUMPAD_3 = 99,
  NUMPAD_4 = 100,
  NUMPAD_5 = 101,
  NUMPAD_6 = 102,
  NUMPAD_7 = 103,
  NUMPAD_8 = 104,
  NUMPAD_9 = 105,
  MULTIPLY = 106,
  ADD = 107,
  SUBTRACT = 109,
  DECIMAL = 110,
  DIVIDE = 111,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,
  NUM_LOCK = 144,
  SCROLL_LOCK = 145,
  SEMICOLON = 186,
  EQUALS = 187,
  COMMA = 188,
  DASH = 189,
  PERIOD = 190,
  FORWARD_SLASH = 191,
  GRAVE_ACCENT = 192,
  OPEN_BRACKET = 219,
  BACK_SLASH = 220,
  CLOSE_BRACKET = 221,
  SINGLE_QUOTE = 222,
}
