import * as React from 'react'
import * as styleVars from '@common/styles/constants'

import { Dispatch, forwardRef } from 'react'
import Select, {
  GroupBase,
  Props as ReactSelectProps,
  createFilter,
} from 'react-select'
import { capitalizeFirstLetter, classNames } from '@common/utils/helpers'
import CreatableSelect from 'react-select/creatable'
import DropdownIndicator from './select/components/dropdown-indicator'
import { keyCodes } from '@common/utils/keycodes'
import styled from '@emotion/styled'
import { useIntl } from '@palqee/intl'

interface CustomStyleOptions {
  border: string
}

export interface ISelectOption {
  value: string | number
  label: string | number
  needsValue?: boolean
  customStyleOptions?: CustomStyleOptions
}

interface SelectProps extends React.ComponentPropsWithRef<'select'> {
  className?: string | undefined
  caption?: string
  errorMessage?: string | undefined
  errorBoard?: boolean | undefined
  isCreatable?: boolean
  onChange?: any
  shouldUsePortal?: boolean
  onInputChange?: any
  setFocusedOption?: Dispatch<React.SetStateAction<string>>
  onKeyDown?: any
  focusError?: boolean
  readOnly?: boolean
}

const baseStyles = {
  multiValue: (base, state) => {
    const border = state?.data?.customStyleOptions?.border
    return border ? { ...base, border: `${border} !important` } : base
  },
}

type MultiSelectTypes = React.FC<
  SelectProps &
    Partial<ReactSelectProps<ISelectOption, true, GroupBase<ISelectOption>>>
>

const MultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;

  .pq-multiselect-input-board {
    border-radius: 8px;
    width: 100%;
    border: solid 0px rgba(203, 208, 223, 0.3);

    .-error .pq-multiselect__control {
      border-color: ${styleVars.controlErrorRed};
    }
  }

  .pq-multiselect__placeholder {
    opacity: 0.5;
    color: ${styleVars.darkerGrey};
  }

  .pq-multiselect-input-title {
    position: absolute;
    margin-left: 16px;
    margin-top: -10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  .pq-multiselect {
    width: 100%;
    border-radius: 8px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
    box-sizing: border-box;
  }
  .pq-multiselect__control {
    min-height: 48px;
    border: solid 2px;
    border-radius: 8px;
    color: #0d1331;
    border-color: ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    background-color: white;
    box-shadow: none;
    flex-wrap: nowrap;
    cursor: text;
    &:focused {
      border: solid 2px ${(props) => props.color || '#3f6db4'};
    }
    &:focused {
      border: solid 2px ${(props) => props.color || '#3f6db4'};
    }
  }
  .pq-multiselect__control--is-focused {
    border: solid 2px #3f6db4;
    &:focused {
      border: solid 2px ${(props) => props.color || '#3f6db4'};
    }
    &:hover {
      border: solid 2px ${(props) => props.color || '#3f6db4'};
    }
  }

  .pq-multiselect__control--menu-is-focused {
    border: solid 2px ${(props) => props.color || '#3f6db4'};
  }
  .pq-multiselect__control--menu-is-open {
    border: solid 2px ${(props) => props.color || '#3f6db4'};
  }
  .pq-multiselect__option--is-selected {
    color: black;
    background-color: white;
  }

  .pq-multiselect__option--is-focused {
    color: black;
    background-color: #eff3f9;
  }

  .pq-multiselect__value-container {
    margin-left: 12px;
  }
  .pq-multiselect__menu {
    z-index: 2;
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4);
  }
  .pq-multiselect__option {
    padding-left: 24px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
    cursor: pointer;
  }
  .pq-multiselect__menu-list {
    max-height: 200px;
  }
  .pq-multiselect__multi-value {
    background-color: rgba(203, 208, 223, 0.15);
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #1a3154;
    cursor: pointer;
  }
  .pq-multiselect__indicator-separator {
    display: none;
  }
  .pq-multiselect__indicator {
    display: none;
  }
`

/**
 * @todo similar attributes to form/select look to refactor
 * and either combine or provide a clean separation or resuse of
 * related configs
 */
export const MultiSelect: MultiSelectTypes = forwardRef(
  (props, ref?: React.Ref<any>) => {
    const intl = useIntl()
    const {
      className,
      caption,
      name,
      placeholder = `${intl.formatMessage({
        id: 'SELECT',
        defaultMessage: 'Select',
      })}`,
      errorMessage,
      errorBoard,
      options,
      setFocusedOption,
      shouldUsePortal = false,
      onInputChange,
      onKeyDown,
      isCreatable,
      readOnly,
      filterOption,
      focusError,
      components,
      styles,
      ...rest
    } = props

    const classes = classNames(
      'pq-multiselect',
      className || '',
      ((errorMessage || focusError) && '-error') || '',
    )
    const boardColor = errorMessage ? 'rgba(255, 105, 109, 0.5)' : ''
    const filterConfig: {
      ignoreCase: boolean
      matchFrom: 'start'
    } = {
      ignoreCase: true,
      matchFrom: 'start',
    }
    const [inputText, setInputText] = React.useState('')

    // using react-select aria-live-messages
    // we can capture selected option
    // and return to parent element
    // https://react-select.com/advanced#accessibility
    const onFocus = (focused) => {
      if (setFocusedOption) {
        setFocusedOption(focused?.label)
      }
    }

    const ariaLiveMessages = { onFocus }

    const renderSelect = () => {
      // @todo fix react-select types
      const selectProps: any = {
        ref,
        menuPortalTarget:
          shouldUsePortal && typeof window !== 'undefined'
            ? document.body
            : undefined,
        menuPlacement: 'auto',
        inputValue: inputText,
        onInputChange: (text) => {
          if (onInputChange) {
            onInputChange(text)
          }

          setInputText(text)
        },
        onKeyDown: (e) => {
          if (onKeyDown) {
            return onKeyDown(e, inputText)
          }

          switch (e.key) {
            case keyCodes.ENTER: // enter
              setInputText('')
              break
            default:
          }
        },
        className: classes,
        classNamePrefix: 'pq-multiselect',
        styles: {
          ...baseStyles,
          ...styles,
        },
        ['aria-labelledby']: 'aria-label',
        ariaLiveMessages,
        options,
        isMulti: true,
        name,
        components: {
          DropdownIndicator,
          ...components,
        },
        isClearable: !readOnly,
        isSearchable: !readOnly,
        openMenuOnClick: !readOnly,
        instanceId: name,
        placeholder,
        readOnly,
        menuIsOpen: readOnly ? false : undefined,
        formatCreateLabel: (input) => capitalizeFirstLetter(input),
        filterOption: (candidate, input) => {
          if (filterOption) {
            return filterOption(candidate, input)
          }

          return createFilter(filterConfig)(candidate, input)
        },
        ...rest,
      }

      if (isCreatable) {
        return <CreatableSelect {...selectProps} />
      }

      return <Select {...selectProps} />
    }

    return (
      <MultiSelectWrapper color={boardColor}>
        <div className="pq-multiselect-input-board" data-cy="form-multiselect">
          <span className="pq-multiselect-input-title"> {`${caption}`}</span>
          {renderSelect()}
        </div>
        {errorBoard && <div className="pq-error-message">{errorMessage}</div>}
      </MultiSelectWrapper>
    )
  },
)
