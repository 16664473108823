import * as styleVars from '@common/styles/constants'

import {
  GroupBase,
  createFilter,
  Props as ReactSelectProps,
} from 'react-select'
import { TableSelectWrapper, commonStyles } from '../select/styles'
import { capitalizeFirstLetter, classNames } from '@common/utils/helpers'

import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import { IFilterConfig } from '../select/index'
import React from 'react'
import { css } from '@emotion/react'
import toolTipSingleValue from '../select/components/single-value-tooltip'
import { useIntl } from '@palqee/intl'
// src
import { useOverflowDetect } from '@common/utils/use-hooks'
import { CreatableProps } from 'react-select/creatable'

const handleSelectStyling = (styleParams): any => {
  const tableStyle = {
    ...commonStyles,
    container: (styles, { selectProps }) => ({
      ...styles,
      // remove pointer events when isOverflowed
      // to display tooltip
      pointerEvents: selectProps.isOverflowed ? 'unset' : 'none',
    }),
    control: (_, { selectProps }) => ({
      width: selectProps.width,
    }),
    placeholder: (_, { isFocused }) => ({
      display: isFocused ? 'none' : 'block',
      color: '#8a91a5',
      opacity: '.5',
      // select value font-size seems to be different for 13
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.54',
      letterSpacing: 'normal',
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0',
      color: '#0d1331',
    }),
    input: () => ({}),
    indicatorsContainer: (styles) => ({
      ...styles,
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      width: styleParams.menuWidth ?? '100%',
      zIndex: '2',
      left: '-15px',
      top: '40px',
      boxShadow: '0 2px 16px 0 rgba(203, 208, 223, 0.4)',
      margin: '0',
      padding: '0',
      marginTop: '40px',
    }),
    menuList: (styles) => ({
      ...styles,
      margin: '0',
      padding: '0',
    }),
    singleValue: (_, { selectProps }) => ({
      // select value font-size seems to be different for 13
      display: selectProps.menuIsOpen ? 'none' : 'block',
      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.54',
      letterSpacing: 'normal',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '105px',
      textOverflow: 'ellipsis',
      '::WebkitScrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
    }),
    option: (styles, state) => {
      return {
        ...styles,
        ...commonStyles.option(styles),
        fontWeight: 500,
        fontSize: 13,
        width: '100%',
        cursor: 'pointer',
        color: state.isFocused
          ? `${styleVars.primaryBlue} !important`
          : 'inherit',
        ':hover': {
          color: `${styleVars.primaryBlue} !important`,
        },
        ':active': {
          backgroundColor: '#DEEBFF',
        },
      }
    },
  }

  return tableStyle
}

export interface PqAsyncTableSelectProps {
  isCreatable?: boolean
  borderRadius?: string
  inputMarginLeft?: number
  isTableSelect?: boolean
  id?: string
  name?: string
  className?: string
  disabled?: boolean
  caption?: string
  placeholder?: string
  errorMessage?: string
  loadOptions?: any
  menuWidth?: string
  isOverflowed?: string
}

const PqAsyncTableSelect: React.FC<
  React.PropsWithChildren<
    PqAsyncTableSelectProps &
      Partial<ReactSelectProps<any, boolean, GroupBase<any>>> &
      CreatableProps<any, boolean, GroupBase<any>>
  >
> = (props) => {
  const intl = useIntl()
  const {
    isCreatable,
    id,
    name,
    className,
    placeholder = intl.formatMessage({
      id: 'SELECT',
      defaultMessage: 'Select',
    }),
    isTableSelect,
    loadOptions,
    menuWidth,
    noOptionsMessage = () => (
      <span
        css={css`
          font-size: 11px;
        `}
      >
        {intl.formatMessage({
          id: 'NO_OPTIONS',
          defaultMessage: 'No options available',
        })}
      </span>
    ),
    ...rest
  } = props

  const classes = classNames('pq-select', className || '')
  const customStyle = handleSelectStyling({ menuWidth })

  const [isOverflowed, overflowRef] = useOverflowDetect()

  const filterConfig: IFilterConfig = {
    ignoreCase: true,
    matchFrom: 'start',
  }

  if (isCreatable) {
    return (
      <TableSelectWrapper>
        <AsyncCreatableSelect
          id={id}
          menuPlacement="auto"
          className={classes}
          styles={customStyle}
          placeholder={placeholder}
          name={name}
          loadOptions={loadOptions}
          cacheOptions
          defaultOptions
          instanceId={name}
          isOverflowed
          noOptionsMessage={noOptionsMessage}
          components={{
            SingleValue: (singleValueProps) => {
              return toolTipSingleValue(
                singleValueProps,
                overflowRef,
                isOverflowed,
              )
            },
          }}
          filterOption={createFilter(filterConfig)}
          formatCreateLabel={(input) => capitalizeFirstLetter(input)}
          {...rest}
        />
      </TableSelectWrapper>
    )
  }

  if (isTableSelect) {
    return (
      <TableSelectWrapper>
        <AsyncSelect
          menuPlacement="auto"
          id={id}
          className={classes}
          styles={customStyle}
          placeholder={placeholder}
          name={name}
          noOptionsMessage={noOptionsMessage}
          loadOptions={loadOptions}
          cacheOptions
          defaultOptions
          instanceId={name}
          {...rest}
        />
      </TableSelectWrapper>
    )
  }
}

export default PqAsyncTableSelect
