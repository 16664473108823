import { DropdownIndicatorProps, components } from 'react-select'
import SvgDropdown from '@static/svg/icons/dropdown.svg'

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <SvgDropdown width={16} height={16} />
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
