import { forwardRef } from 'react'

import { classNames } from '@common/utils/helpers'
import styled from '@emotion/styled'
import { useCombinedRefs } from '@common/utils/use-hooks'
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize'
import { FieldErrors } from 'react-hook-form'
import { FormElementError } from './error'
import { SerializedStyles } from '@emotion/react'

export interface TextAreaProps extends TextareaAutosizeProps {
  errorMessage?: string | undefined
  errors?: FieldErrors
  refRegister?: string | undefined
  ref?: React.MutableRefObject<any>
  wrapperClassName?: string
  errorBoard?: boolean
  focusError?: boolean
  errorBoardCss?: any
  caption?: string
  wrapperCss?: SerializedStyles
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, forwardedRef) => {
    const {
      className,
      refRegister,
      wrapperClassName,
      errorBoard,
      errorMessage,
      errorBoardCss,
      focusError,
      caption,
      errors,
      wrapperCss,
      ...rest
    } = props

    const classes = classNames('pq-textarea', className || '')
    const borderColor =
      (errors && !!errors[rest.name]) || !!errorMessage || focusError
        ? 'rgba(255, 105, 109, 0.5)'
        : ''

    const ref = useCombinedRefs(refRegister, forwardedRef)

    return (
      <TextAreaInputWrapper
        color={borderColor}
        className={wrapperClassName}
        css={wrapperCss}
      >
        {caption && <span className="pq-textarea__caption">{caption} </span>}
        <TextareaAutosize
          data-cy="form-textarea"
          className={classes}
          ref={ref}
          autoComplete="off"
          {...rest}
        ></TextareaAutosize>
        <FormElementError
          name={rest.name}
          errorMessage={errorMessage}
          errors={errors}
          showErrorBoard={errorBoard}
          css={errorBoardCss}
        />
      </TextAreaInputWrapper>
    )
  },
)

const TextAreaInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .pq-textarea {
    width: 100%;
    border-radius: 8px;
    border: solid 2px ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    outline: 0;
    white-space: pre-line;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    padding: 0.625em 0.625em 0.625em 1.75em;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;

    &__caption {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 16px;
      margin-top: -10px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #8a91a5;
      background-color: white;
      white-space: nowrap;
      padding-left: 8px;
      padding-right: 8px;
      opacity: 1 !important;
    }
  }
`
