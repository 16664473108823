import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { CustomPicker } from 'react-color'
import {
  Saturation,
  EditableInput,
  Hue,
  Checkboard,
} from 'react-color/lib/components/common'

interface ColorPickerProps {
  className?: string | undefined
  color?: any
  setColor?: any
  name?: any
  refRegister?: any
}

type ColorPicker = React.FC<React.PropsWithChildren<ColorPickerProps>>

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .pq-color-picker-swatch {
    width: 100%;
    height: 100%;
    padding: 8px;
    background: #fff;
    border-radius: 8px;
    border: solid 2px rgba(203, 208, 223, 0.3);
    display: inline-block;
    cursor: pointer;
  }

  .pq-color-picker-color {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: ${(props) => props.color};
  }
  .pq-color-picker-popover {
    position: relative;
    z-index: 2;
    top: -165px;
    left: 50px;
  }
  .pq-color-picker-cover {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }
  .pq-color-picker-input {
    position: fixed;
    top: 0px;
    left: 0px;
    opacity: 0;
  }
  .pq-color-picker-dropdown-mark {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`
const CustomColorPickerWrapper = styled.div`
  width: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(203, 208, 223, 0.4);
  background-color: #ffffff;
  padding: 16px 16px 8px 16px;

  .pq-saturation {
    width: 218px;
    height: 218px;
    margin-bottom: 8px;
    position: relative;
    div {
      border-radius: 4px !important;
    }
  }
  .pq-slider-row {
    width: 218px;
    height: 20px;
    align-items: center;
    margin-bottom: 8px;
  }
  .pq-hue {
    height: 16px;
    margin-right: 8px;
    position: relative;
    div {
      border-radius: 4px !important;
    }
  }
  .pq-checkboard {
    flex: none;
    width: 20px;
    height: 20px;
    position: relative;
    div {
      border-radius: 4px !important;
    }
  }
  .pq-editable-input {
    div {
      display: flex;
      flex-direction: column;
    }
    input {
      &:focus {
        border: solid 2px #3f6db4 !important;
      }
    }
  }
`
const customSliderPointer = () => (
  <div
    css={css`
      display: block;
      position: relative;
      top: -2px;
      width: 16px;
      height: 20px;
      border-radius: 4px;
      box-shadow: 2px 2px 16px 0 rgba(148, 154, 171, 0.4);
      background-color: #ffffff;
    `}
  />
)

const customSaturationPointer = () => (
  <span
    css={css`
      display: block;
      position: relative;
      top: -8px;
      left: -8px;
      width: 16px;
      height: 16px;
      border: solid 2px #ffffff;
      border-radius: 50%;
    `}
  />
)

const HexInputStyle = {
  input: {
    outline: 0,
    height: 30,
    borderRadius: 4,
    border: 'solid 2px rgba(203, 208, 223, 0.3)',
    backgroundColor: '#ffffff',
    fontSize: '13px',
    lineHeight: '1.54',
    textAlign: 'center',
    color: '#0d1331',
    marginBottom: '4px',
  },
  label: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#8a91a5',
  },
}
const MyColorPicker = (props) => {
  const rgbaToHex = (rCol, gCol, bCol) => {
    let rColor = ''
    let gColor = ''
    let bColor = ''
    rColor = rCol.toString(16)
    gColor = gCol.toString(16)
    bColor = bCol.toString(16)

    if (rColor.length === 1) rColor = `0${rColor}`
    if (gColor.length === 1) gColor = `0${gColor}`
    if (bColor.length === 1) bColor = `0${bColor}`

    return `#${rColor}${gColor}${bColor}`
  }

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return (
      result && {
        rColor: parseInt(result[1], 16),
        gColor: parseInt(result[2], 16),
        bColor: parseInt(result[3], 16),
      }
    )
  }

  const [r, setR] = useState(hexToRgb(props.color.hex).rColor)
  const [g, setG] = useState(hexToRgb(props.color.hex).gColor)
  const [b, setB] = useState(hexToRgb(props.color.hex).bColor)
  const [a, setA] = useState(100)

  useEffect(() => {
    props.onChange({ hex: rgbaToHex(r, g, b), rgb: { r, g, b, a } })
  }, [r, g, b, a])
  useEffect(() => {
    setR(hexToRgb(props.color.hex).rColor)
    setG(hexToRgb(props.color.hex).gColor)
    setB(hexToRgb(props.color.hex).bColor)
  }, [props.color])
  return (
    <CustomColorPickerWrapper>
      <div className="pq-container">
        <div className="pq-row pq-saturation">
          <Saturation pointer={customSaturationPointer} {...props} />
        </div>
        <div className="pq-row pq-slider-row">
          <div className="pq-col pq-hue">
            <Hue pointer={customSliderPointer} {...props} />
          </div>
          <div className="pq-col pq-checkboard">
            <Checkboard
              size={1}
              white={props.color.hex}
              grey={props.color.hex}
            />
          </div>
        </div>
        <div
          className="pq-row"
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            className="pq-col pq-editable-input"
            css={css`
              width: 74px;
              margin-right: 4px;
            `}
          >
            <EditableInput
              style={HexInputStyle}
              label="HEX"
              value={props.color.hex}
              onChange={(ev) => {
                props.onChange({ hex: ev['HEX'] })
              }}
            />
          </div>
          <div
            className="pq-col pq-editable-input"
            css={css`
              width: 32px;
              margin-right: 4px;
            `}
          >
            <EditableInput
              style={HexInputStyle}
              label="R"
              value={r}
              onChange={(ev) => {
                setR(parseInt(ev['R'], 10))
              }}
            />
          </div>
          <div
            className="pq-col pq-editable-input"
            css={css`
              width: 32px;
              margin-right: 4px;
            `}
          >
            <EditableInput
              style={HexInputStyle}
              label="G"
              value={g}
              onChange={(ev) => {
                setG(parseInt(ev['G'], 10))
              }}
            />
          </div>
          <div
            className="pq-col pq-editable-input"
            css={css`
              width: 32px;
              margin-right: 4px;
            `}
          >
            <EditableInput
              style={HexInputStyle}
              label="B"
              value={b}
              onChange={(ev) => {
                setB(parseInt(ev['B'], 10))
              }}
            />
          </div>
          <div
            className="pq-col pq-editable-input"
            css={css`
              width: 32px;
            `}
          >
            <EditableInput
              style={HexInputStyle}
              label="A"
              value={a}
              onChange={(ev) => {
                setA(ev['A'])
              }}
            />
          </div>
        </div>
      </div>
    </CustomColorPickerWrapper>
  )
}

const CustomColorPicker = CustomPicker(MyColorPicker)

export const ColorPicker: ColorPicker = ({
  /* className, */
  name,
  refRegister,
  color,
  setColor,
  ...rest
}) => {
  const [displayColorPicker, showColorPicker] = useState(false)
  const [pickerColor, setPickerColor] = useState(color)

  const onSelectPicker = () => {
    showColorPicker(!displayColorPicker)
  }

  const onCloseCover = () => {
    showColorPicker(false)
  }
  const onColorChange = (col) => {
    setPickerColor(col.hex)
    setColor(col.hex)
  }

  return (
    <Wrapper color={pickerColor} {...rest}>
      <div className="pq-color-picker-swatch" onClick={onSelectPicker}>
        <div className="pq-color-picker-color">
          <div className="pq-color-picker-dropdown-mark">
            {displayColorPicker ? (
              <span className="pq-icon -small-dropup" />
            ) : (
              <span className="pq-icon -small-dropdown" />
            )}
          </div>
        </div>
      </div>

      {displayColorPicker && (
        <div className="pq-color-picker-popover">
          <div className="pq-color-picker-cover" onClick={onCloseCover} />
          <CustomColorPicker
            color={{ hex: pickerColor }}
            onChange={onColorChange}
            presetColors={[]}
            disableAlpha
          />
        </div>
      )}
      <input
        className="pq-color-picker-input"
        name={name}
        ref={refRegister}
        value={pickerColor}
        readOnly
      />
    </Wrapper>
  )
}
