import * as React from 'react'

import { AsyncMultiSelect } from '@common/components/form/async-multi-select'
import { AsyncPairSelect } from '@common/components/form/async-pair-select'
import { CheckBox } from './checkbox'
import { ColorPicker } from './color-picker'
import { GroupSelect } from './group-select'
import { Input } from './input'
import { InputGroup } from './input-group'
import { MultiSelect } from './multi-select'
import { PairSelect } from './pair-select'
import PhoneNumberInput from './input-phone-number'
import { PqAsyncSelect } from '@common/components/form/async-select'
import PqAsyncTableSelect from '@common/components/form/async-table-select'
import { PqDatePicker } from './datepicker/index'
import PqSelect from './select/index'
import PqTableSelect from './select/table'
import { RadioButton } from './radiobutton'
import { Star } from './star'
import { TextArea } from './text-area'
import { CounterInput } from './counter-input'
import { classNames } from '@common/utils/helpers'

const Form = React.forwardRef<
  HTMLFormElement,
  React.ComponentPropsWithoutRef<'form'>
>((props, ref) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-form', className || '')

  return (
    <form className={classes} ref={ref} {...rest}>
      {children}
    </form>
  )
})

const FormNamespace = Object.assign(Form, {
  Input,
  InputGroup,
  InputPhoneNumber: PhoneNumberInput,
  MultiSelect,
  AsyncMultiSelect,
  PairSelect,
  AsyncPairSelect,
  CheckBox,
  RadioButton,
  ColorPicker,
  Star,
  GroupSelect,
  PqSelect,
  PqAsyncSelect,
  PqTableSelect,
  PqAsyncTableSelect,
  PqDatePicker,
  TextArea,
  CounterInput,
})

export { FormNamespace as Form }
