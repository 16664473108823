import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import React, { forwardRef } from 'react'

import 'react-phone-input-2/lib/material.css'
import { IFormErrorProps } from './types'
import { UseFormSetValue } from 'react-hook-form'
import styled from '@emotion/styled'
import { classNames } from '@common/utils/helpers'

export interface PhoneNumberInputProps extends IFormErrorProps {
  caption?: string
  name?: string
  value?: string
  defaultValue?: string
  disabled?: boolean
  placeholder?: string
  phoneInputProps?: PhoneInputProps
  css?: any
  onKeyDown?: () => void
  setValue?: UseFormSetValue<any>
}

/**
 * @deprecated use {@palqee/ui/form/phone-number}
 */
const PhoneNumberInput: React.FC<
  React.PropsWithChildren<PhoneNumberInputProps>
> = forwardRef((props, forwardedRef) => {
  const {
    errorMessage,
    errorBoard,
    name,
    value,
    defaultValue,
    placeholder = '',
    disabled = false,
    setValue,
    caption,
    onKeyDown,
    phoneInputProps,
    ...rest
  } = props
  const normDefVal = defaultValue?.length ? defaultValue : 'gb'

  const captionClass = classNames(
    'pq-input-phone__caption',
    (disabled && '-disabled') || '',
  )

  const borderColor = errorMessage ? 'rgba(255, 105, 109, 0.5)' : ''
  return (
    <PhoneNumberInputWrapper color={borderColor} disabled={disabled}>
      {caption && <span className={captionClass}>{caption}</span>}
      <PhoneInput
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        autocompleteSearch={false}
        specialLabel=""
        inputProps={{
          name,
          ref: forwardedRef,
          autoComplete: 'off',
          'data-cy': 'form-input-phone',
          ...rest,
        }}
        onBlur={() => {
          // set touhed state by doing this
          if (setValue) setValue(name, value)
        }}
        onKeyDown={onKeyDown}
        onChange={(newPhone) => {
          if (setValue) {
            setValue(name, newPhone)
          }
        }}
        country={normDefVal}
        {...phoneInputProps}
      />

      {errorBoard && <div className="pq-error-message">{errorMessage}</div>}
    </PhoneNumberInputWrapper>
  )
})

export default PhoneNumberInput
export const PhoneNumberInputWrapper = styled.div<{
  color: string
  disabled: boolean
}>`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  .pq-input-phone__caption {
    position: absolute;
    margin-left: 16px;
    margin-top: -10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  .react-tel-input input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: solid 2px ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    outline: 0;
    padding-left: 50px;
    font-family: Poppins;
    font-size: 13px;
    line-height: 1.54;
    color: #0d1331;

    &:disabled {
      cursor: auto;
    }

    &:hover {
      border: solid 2px ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    }

    &:focus {
      border: solid 2px #3f6db4;
      box-shadow: none;
    }
  }

  .react-tel-input .flag {
    filter: ${(props) =>
      props.disabled ? 'grayscale(100%)' : 'grayscale(0%)'};
  }
`
