import { ErrorMessage } from '@hookform/error-message'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { ReactNode } from 'react'
import { IFormElementErrorProps } from './types'
import { css } from '@emotion/react'
import { red40a } from '@common/styles/constants'

const FormElementError = (props: IFormElementErrorProps) => {
  const { errors, showErrorBoard, errorMessage, className, name, ...rest } =
    props

  const classes = classNames('pq-form__errors', className)

  const renderError = () => {
    const errorWrapper = (message: ReactNode = '') => {
      return (
        (showErrorBoard || !!errors) && (
          <div
            data-cy="form-input-error-message"
            className={classes}
            {...rest}
            css={css({
              width: '100%',
              fontSize: '0.75rem',
              fontWeight: '600',
              lineHeight: '1.5',
              color: red40a,
              height: '20px',
              textAlign: 'right',
            })}
          >
            {message}
          </div>
        )
      )
    }

    if (!isEmpty(errors)) {
      return errorWrapper(<ErrorMessage errors={errors} name={name} />)
    }

    return errorWrapper(errorMessage)
  }

  return <>{renderError()}</>
}

export { FormElementError }
