import { Placement, inlinePositioning } from 'tippy.js'

import React from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'
import { classNames } from '@common/utils/helpers'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export interface TooltipProps extends TippyProps {
  className?: string | undefined
  contentCss?: any
  showTooltip?: any
  isDisabled?: boolean | undefined
  maxWidth?: string | undefined
  minWidth?: string
  theme?: 'dark' | 'light'
  arrowNubCssPosition?: string
  boxShadow?: boolean
  forceHideTooltip?: boolean
  placement?: Placement
  trigger?: string
  hideOnClick?: boolean
  showArrowNubOntop?: boolean
  style?: any
}

type Tooltip = React.FC<React.PropsWithChildren<TooltipProps>>

const TooltipWrapper = styled(Tippy)`
  &.pq-tooltip {
    display: inline-flex;
    position: relative;
  }
`

/**
 * @deprecated use {@palqee/ui/popover}
 */
const Tooltip: Tooltip = (props) => {
  const {
    className,
    content,
    contentCss = css``,
    children,
    showTooltip,
    maxWidth = '130px',
    minWidth = '0px',
    theme = 'light',
    arrowNubCssPosition = 'left: 50%;',
    boxShadow = true,
    forceHideTooltip = false,
    placement = 'top',
    trigger = 'mouseenter focus',
    hideOnClick = true,
    delay = [500, 0],
    showArrowNubOntop = false,
    style,
    ...tippyProps
  } = props

  const childRef = React.createRef<HTMLDivElement | null>()

  const [shouldShowArrowNubOnTop, setShouldShowArrowNubOnTop] =
    React.useState(false)

  const classes = classNames('pq-tooltip', className || '')

  const getTooltipBackgroundColorClass = (
    toolTipTheme: TooltipProps['theme'],
  ) => {
    switch (toolTipTheme) {
      case 'light':
        return 'pq-background-white'
      default:
        return 'pq-background-dark-blue'
    }
  }

  const getTooltipTextColorClass = (toolTipTheme: TooltipProps['theme']) => {
    switch (toolTipTheme) {
      case 'light':
        return 'pq-color-dark-black'
      default:
        return 'pq-color-faded-white'
    }
  }

  const tooltipBackgroundColorClass = getTooltipBackgroundColorClass(theme)
  const tooltipTextColorClass = getTooltipTextColorClass(theme)
  const boxShadowClass = boxShadow ? 'pq-box-shadow' : ''

  return (
    <TooltipWrapper
      className={classes}
      content={
        <div
          css={css`
            min-width: ${minWidth};
            &:after {
              top: ${shouldShowArrowNubOnTop || showArrowNubOntop
                ? '0%'
                : '100%'};
              ${arrowNubCssPosition};
            }
          `}
          className={`pq-tooltip__container ${tooltipBackgroundColorClass} ${tooltipTextColorClass} ${boxShadowClass}`}
        >
          <div className="pq-tooltip__content" css={contentCss}>
            <>{content}</>
          </div>
        </div>
      }
      disabled={forceHideTooltip || !showTooltip}
      trigger={trigger}
      zIndex={9999}
      maxWidth={maxWidth}
      animation="fade"
      delay={delay}
      duration={[100, 0]}
      placement={placement}
      hideOnClick={hideOnClick}
      touch={['hold', 500]}
      inlinePositioning
      inertia
      onBeforeUpdate={(instance) => {
        if (childRef && childRef.current) {
          const roomFromTopToCurrentElem =
            childRef.current.getBoundingClientRect().top
          const heightOfTooltip = instance.popper.clientHeight

          if (roomFromTopToCurrentElem - heightOfTooltip <= 0) {
            // the tooltip would appear out of bounds
            // popper takes care of displaying the tooltip on top,
            // we just need to take care of the arrow nub
            setShouldShowArrowNubOnTop(true)
          } else {
            setShouldShowArrowNubOnTop(false)
          }
        }
      }}
      plugins={[inlinePositioning]}
      {...tippyProps}
    >
      <span style={style} className="children-wrapper" ref={childRef}>
        {children}
      </span>
    </TooltipWrapper>
  )
}

export default Tooltip
