import { Control, Controller } from 'react-hook-form'
import React, { useState } from 'react'

import DropdownIndicator from './dropdown-indicator'
import { Input } from './input'
import Select from 'react-select'
import { classNames } from '@common/utils/helpers'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

interface SelectProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
  caption?: string
  values?: { values: { value: string; label: string }[] }[]
  names?: any
  errorMessage?: string | undefined
  control?: Control<Record<string, any>>
  errorBoard?: boolean | undefined
  secondary?: boolean | undefined
  defaultOption?: any
}

type PairSelectTypes = React.FC<React.PropsWithChildren<SelectProps>>

const PairSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  .pq-pairselect-input-board {
    border-radius: 8px;
    background-color: #ffffff;
  }
  .pq-pairselect-input-innerboard {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-radius: 8px;
    border: solid 2px ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    height: 48px;
  }
  .pq-pairselect-input-innerboard-focused {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-radius: 8px;
    border: solid 2px ${(props) => props.color || '#3f6db4'};
    outline: 0px;
    box-shadow: none;
    height: 48px;
  }
  .pq-pairselect-input-title {
    position: absolute;
    margin-left: 16px;
    margin-top: -10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  .pq-pairselect-left {
    flex: 1;
    border-radius: 8px 0px 0px 8px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
  }

  .pq-pairselect-left__control {
    min-width: 120px;
    height: 100%;
    border: solid 0px;
    border-radius: 8px 0px 0px 8px;
    color: #0d1331;
    border-color: rgba(203, 208, 223, 0.3);
    box-shadow: none;
    flex-wrap: nowrap;
  }
  .pq-pairselect-left__control--is-focused {
    border: solid 0px #3f6db4;
    &:hover {
      border: solid 0px #3f6db4;
    }
  }

  .pq-pairselect-left__control--menu-is-focused {
    border: 0px;
  }
  .pq-pairselect-left__control--menu-is-open {
    border: 0px;
  }
  .pq-pairselect-left__option {
    padding-left: 24px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
  }
  .pq-pairselect-left__option--is-selected {
    color: #3f6db4;
    background-color: white;
  }

  .pq-pairselect-left__option--is-focused {
    color: #3f6db4;
    background-color: #eff3f9;
  }

  .pq-pairselect-left__value-container {
    margin-left: 12px;
  }
  .pq-pairselect-left__menu {
    width: 100%;
    z-index: 2;
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4);
  }

  .pq-pairselect-right {
    width: 110px;
    ${(props: SelectProps) =>
      props.secondary
        ? css`
            width: 260px;
          `
        : css`
            width: 110px;
          `}
    border-radius: 0px 8px 8px 0px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
  }

  .pq-pairselect-right__control {
    height: 100%;
    border: 0px;
    border-radius: 0px 8px 8px 0px;
    color: #0d1331;
    border-color: ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    box-shadow: inset 0px 0 0 0 #f0f1f6;
    background-color: rgba(203, 208, 223, 0.15);
  }
  .pq-pairselect-right__control--is-focused {
    border: 0px;
    &:focused {
      border: 0px;
    }
    &:hover {
      border: 0px;
    }
  }

  .pq-pairselect-right--menu-is-focused {
    border: 0px;
  }
  .pq-pairselect-right--menu-is-open {
    border: 0px;
  }
  .pq-pairselect-right__option {
    padding-left: 24px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
  }
  .pq-pairselect-right__option--is-selected {
    color: #3f6db4;
    background-color: white;
  }

  .pq-pairselect-right__option--is-focused {
    color: #3f6db4;
    background-color: #eff3f9;
  }

  .pq-pairselect-right__value-container {
    margin-left: 12px;
  }
  .pq-pairselect-right__menu {
    width: 100%;
    z-index: 2;
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: none;
  }
  .pq-pairselect-left__indicator-separator {
    display: none;
  }
  .pq-pairselect-right__indicator-separator {
    display: none;
  }
`

export const PairSelect: PairSelectTypes = (props) => {
  const {
    /* children, */
    className,
    caption,
    names,
    errorMessage,
    errorBoard,
    values,
    control,
    secondary,
    defaultOption,
    ...rest
  } = props
  const leftClasses = classNames('pq-pairselect-left', className || '')
  const rightClasses = classNames('pq-pairselect-right', className || '')
  const [pairSelectBoardStyle, setPairSelectBoardStyle] = useState(
    classNames('pq-pairselect-input-innerboard'),
  )
  const classes = classNames('pq-pairselect-input-board', className || '')
  const borderColor = errorMessage ? 'rgba(255, 105, 109, 0.5)' : ''

  return (
    <PairSelectWrapper
      data-cy="form-pairselect"
      color={borderColor}
      secondary={secondary}
    >
      <div className={classes}>
        <span className="pq-pairselect-input-title"> {`${caption}`}</span>
        <div
          className={pairSelectBoardStyle}
          onFocus={() => {
            setPairSelectBoardStyle('pq-pairselect-input-innerboard-focused')
          }}
          onBlur={() => {
            setPairSelectBoardStyle('pq-pairselect-input-innerboard')
          }}
        >
          {secondary && (
            <Controller
              render={({ field }) => (
                <Input {...field} className={leftClasses} {...rest} />
              )}
              name={names[0]}
              control={control}
              rules={{ required: 'Field is Empty' }}
            />
          )}
          {!secondary && (
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  className={leftClasses}
                  classNamePrefix="pq-pairselect-left"
                  {...rest}
                  options={values[0].values}
                  instanceId={`left-select-${names[0]}`}
                  isSearchable={false}
                  components={{ DropdownIndicator }}
                />
              )}
              name={names[0]}
              control={control}
              rules={{ required: 'Field is Empty' }}
            />
          )}
          <Controller
            render={({ field }) => (
              <Select
                {...field}
                className={rightClasses}
                classNamePrefix="pq-pairselect-right"
                {...rest}
                options={values[1].values}
                instanceId={`right-select-${names[1]}`}
                defaultValue={
                  !secondary ? { value: 'USD', label: 'USD' } : defaultOption
                }
                isSearchable={false}
                components={{ DropdownIndicator }}
              />
            )}
            name={names[1]}
            control={control}
            rules={{ required: 'Field is Empty' }}
          />
        </div>
      </div>
      {errorBoard && <div className="pq-error-message">{errorMessage}</div>}
    </PairSelectWrapper>
  )
}
