import * as styleVars from '@common/styles/constants'

import { Control, Controller, useFormContext } from 'react-hook-form'
import React, { useState } from 'react'

import DropdownIndicator from './dropdown-indicator'
import Select from 'react-select'
import { classNames } from '@common/utils/helpers'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

type SelectField = {
  key?: number | string
  name: string
  // value: string
  errorMessage?: string
  options: { value: string | number; label: string }[]
  defaultValue?: string | number
  onChange?: (e: any) => void
}

interface SelectProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
  caption?: string
  values?: { values: { value: string; label: string }[] }[]
  names?: any
  errorMessage?: string | undefined
  hasInnerError?: boolean
  shouldUsePortal?: boolean
  control?: Control<Record<string, any>>
  errorBoard?: boolean | undefined
  secondary?: boolean | undefined
  defaultOption?: any
  selectFields?: SelectField[]
  backgroundColor?: string
}

type GroupSelectTypes = React.FC<React.PropsWithChildren<SelectProps>>

export const GroupSelect: GroupSelectTypes = (props) => {
  const {
    className,
    caption,
    errorMessage,
    errorBoard,
    secondary,
    selectFields = [],
    backgroundColor,
    hasInnerError,
    shouldUsePortal,
    ...rest
  } = props
  const leftClasses = classNames('pq-pairselect-left', className || '')
  const rightClasses = classNames('pq-pairselect-right', className || '')
  const middleClasses = classNames('pq-pairselect-middle', className || '')
  const [pairSelectBoardStyle, setGroupSelectBoardStyle] = useState(
    classNames('pq-pairselect-input-innerboard'),
  )
  const classes = classNames('pq-pairselect-input-board', className || '')
  const borderColor =
    hasInnerError || errorMessage ? 'rgba(255, 105, 109, 0.5)' : ''

  const { control } = useFormContext() ?? {}

  const renderSelect = (
    options: any,
    name: string,
    defaultValue: any,
    classPrefix: string,
    renderSelectClasses: string,
    onChange?: (e: any) => void,
    renderSelectErrorMessage?: string,
    key?: string | number,
  ) => {
    return (
      <div
        key={key}
        css={css`
          flex: 1;
        `}
        data-cy={classPrefix}
      >
        <Controller
          key={`group-select-${name}`}
          render={({ field, field: { onChange: onRenderChange } }) => (
            <Select
              {...field}
              {...rest}
              css={css`
                width: 100%;
                height: 100%;
              `}
              className={renderSelectClasses}
              classNamePrefix={classPrefix}
              options={options}
              menuPortalTarget={
                shouldUsePortal && typeof window !== 'undefined'
                  ? document.body
                  : undefined
              }
              instanceId={`group-select-${name}`}
              isSearchable={false}
              components={{ DropdownIndicator }}
              onChange={(selected) => {
                onRenderChange(selected)
                if (onChange) {
                  onChange(selected)
                }
              }}
            />
          )}
          defaultValue={defaultValue}
          name={name}
          control={control}
          rules={{ required: 'Field is Empty' }}
        />

        {errorBoard && (
          <div className="pq-mt-1 pq-error-message">
            {renderSelectErrorMessage}
          </div>
        )}
      </div>
    )
  }

  const renderFormInputs = (): JSX.Element | null => {
    if (!selectFields.length) return null

    if (selectFields.length === 1) {
      return renderSelect(
        selectFields[0].options,
        selectFields[0].name,
        selectFields[0].defaultValue,
        'pq-pairselect-left',
        leftClasses,
        selectFields[0].onChange,
        selectFields[0].errorMessage,
      )
    }

    if (selectFields.length === 2) {
      return (
        <div>
          {renderSelect(
            selectFields[0].options,
            selectFields[0].name,
            selectFields[0].defaultValue,
            'pq-pairselect-left',
            leftClasses,
            selectFields[0].onChange,
            selectFields[0].errorMessage,
          )}
          {renderSelect(
            selectFields[1].options,
            selectFields[1].name,
            selectFields[1].defaultValue,
            'pq-pairselect-right',
            rightClasses,
            selectFields[1].onChange,
            selectFields[1].errorMessage,
          )}
        </div>
      )
    }

    const middleFields = selectFields.slice(1, selectFields.length - 1)
    const middleSelects = middleFields.map((selectField, idx) =>
      renderSelect(
        selectField.options,
        selectField.name,
        selectField.defaultValue,
        'pq-pairselect-middle',
        middleClasses,
        selectField.onChange,
        selectField.errorMessage,
        idx,
      ),
    )

    return (
      <div
        css={css`
          width: 100%;
          display: flex;
        `}
      >
        {renderSelect(
          selectFields[0].options,
          selectFields[0].name,
          selectFields[0].defaultValue,
          'pq-pairselect-left',
          leftClasses,
          selectFields[0].onChange,
          selectFields[0].errorMessage,
        )}
        {/* render middle */}
        {middleSelects}
        {renderSelect(
          selectFields[selectFields.length - 1].options,
          selectFields[selectFields.length - 1].name,
          selectFields[selectFields.length - 1].defaultValue,
          'pq-pairselect-right',
          rightClasses,
          selectFields[selectFields.length - 1].onChange,
          selectFields[selectFields.length - 1].errorMessage,
        )}
      </div>
    )
  }

  return (
    <GroupSelectWrapper
      color={borderColor}
      secondary={secondary}
      backgroundColor={backgroundColor}
    >
      <div className={classes}>
        <span className="pq-pairselect-input-title"> {`${caption}`}</span>
        <div
          className={pairSelectBoardStyle}
          onFocus={() => {
            setGroupSelectBoardStyle('pq-pairselect-input-innerboard-focused')
          }}
          onBlur={() => {
            setGroupSelectBoardStyle('pq-pairselect-input-innerboard')
          }}
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex={1} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        >
          {renderFormInputs()}
        </div>
      </div>
      {errorBoard && <div className="pq-error-message">{errorMessage}</div>}
    </GroupSelectWrapper>
  )
}

const GroupSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;

  .pq-pairselect-input-board {
    border-radius: 8px;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : styleVars.white};
    width: 100%;
  }
  .pq-pairselect-input-innerboard {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-radius: 8px;
    border: solid 2px ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    height: 48px;
    width: 100%;
  }
  .pq-pairselect-input-innerboard-focused {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-radius: 8px;
    border: solid 2px ${(props) => props.color || '#3f6db4'};
    outline: 0px;
    box-shadow: none;
    height: 48px;
  }
  .pq-pairselect-input-title {
    position: absolute;
    margin-left: 16px;
    margin-top: -10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  .pq-pairselect-left {
    flex: 1;
    border-radius: 8px 0px 0px 8px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
  }

  .pq-pairselect-left__control {
    min-width: 120px;
    height: 100%;
    border: solid 0px;
    border-radius: 8px 0px 0px 8px;
    color: #0d1331;
    border-color: rgba(203, 208, 223, 0.3);
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : styleVars.white};
    // background-color: rgba(203, 208, 223, 0.15);
    box-shadow: none;
    flex-wrap: nowrap;
  }
  .pq-pairselect-left__control--is-focused {
    border: solid 0px #3f6db4;
    &:hover {
      border: solid 0px #3f6db4;
    }
  }

  .pq-pairselect-left__control--menu-is-focused {
    border: 0px;
  }
  .pq-pairselect-left__control--menu-is-open {
    border: 0px;
  }
  .pq-pairselect-left__option {
    padding-left: 24px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
  }
  .pq-pairselect-left__option--is-selected {
    color: #3f6db4;
    background-color: white;
  }

  .pq-pairselect-left__option--is-focused {
    color: #3f6db4;
    background-color: #eff3f9;
  }

  .pq-pairselect-left__value-container {
    margin-left: 12px;
  }
  .pq-pairselect-left__menu {
    width: 100%;
    z-index: 2;
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4);
  }

  .pq-pairselect-left {
    flex: 1;
    border-radius: 8px 0px 0px 8px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
  }

  .pq-pairselect-middle__control {
    min-width: 120px;
    height: 100%;
    border: solid 0px;
    border-radius: 8px 0px 0px 8px;
    color: #0d1331;
    border-color: rgba(203, 208, 223, 0.3);
    // background-color: rgba(203, 208, 223, 0.15);
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : styleVars.white};
    box-shadow: none;
    flex-wrap: nowrap;
  }
  .pq-pairselect-middle__control--is-focused {
    border: solid 0px #3f6db4;
    &:hover {
      border: solid 0px #3f6db4;
    }
  }

  .pq-pairselect-middle__control--menu-is-focused {
    border: 0px;
  }
  .pq-pairselect-middle__control--menu-is-open {
    border: 0px;
  }
  .pq-pairselect-middle__option {
    padding-left: 24px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
  }
  .pq-pairselect-middle__option--is-selected {
    color: #3f6db4;
    background-color: white;
  }

  .pq-pairselect-middle__option--is-focused {
    color: #3f6db4;
    background-color: #eff3f9;
  }

  .pq-pairselect-middle__value-container {
    margin-left: 12px;
  }
  .pq-pairselect-middle__menu {
    width: 100%;
    z-index: 2;
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4);
  }

  .pq-pairselect-right {
    ${(props: SelectProps) =>
      props.secondary
        ? css`
            width: 260px;
          `
        : css``}
    border-radius: 0px 8px 8px 0px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
  }

  .pq-pairselect-right__control {
    height: 100%;
    border: 0px;
    border-radius: 0px 8px 8px 0px;
    color: #0d1331;
    border-color: ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    box-shadow: inset 0px 0 0 0 #f0f1f6;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : styleVars.white};
  }
  .pq-pairselect-right__control--is-focused {
    border: 0px;
    &:focused {
      border: 0px;
    }
    &:hover {
      border: 0px;
    }
  }

  .pq-pairselect-right--menu-is-focused {
    border: 0px;
  }
  .pq-pairselect-right--menu-is-open {
    border: 0px;
  }
  .pq-pairselect-right__option {
    padding-left: 24px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
  }
  .pq-pairselect-right__option--is-selected {
    color: #3f6db4;
    background-color: white;
  }

  .pq-pairselect-right__option--is-focused {
    color: #3f6db4;
    background-color: #eff3f9;
  }

  .pq-pairselect-right__value-container {
    margin-left: 12px;
  }
  .pq-pairselect-right__menu {
    width: 100%;
    z-index: 2;
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: none;
  }
  .pq-pairselect-left__indicator-separator {
    display: none;
  }
  .pq-pairselect-middle__indicator-separator {
    display: none;
  }
  .pq-pairselect-right__indicator-separator {
    display: none;
  }
`
