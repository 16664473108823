// src
import * as styleVars from '@common/styles/constants'

import React, { useState } from 'react'
import { useCombinedRefs, useOverflowDetect } from '@common/utils/use-hooks'

import SvgVisibility from '@static/svg/icons/visibility.svg'
import Tooltip from '@common/components/tooltip'
import { classNames } from '@common/utils/helpers'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FieldErrors, useFormContext } from 'react-hook-form'
import { FormElementError } from './error'

export interface InputProps extends React.ComponentProps<'input'> {
  className?: string | undefined
  caption?: string | JSX.Element
  errorMessage?: string
  errors?: FieldErrors
  refRegister?: (input?: any) => void | undefined
  placeholder?: string
  isTableInput?: boolean
  disabled?: boolean
  noFocus?: boolean
  errorBoard?: boolean
  errorBoardCss?: any
  isPassword?: boolean
  isDraggable?: boolean
  isSearchBox?: boolean
  showDragbar?: boolean
  isOverflowed?: boolean | undefined
  showPlaceholderMode?: boolean
  pqInputBoardCss?: any | undefined
  type?: string
  name?: string
  ref?: React.MutableRefObject<any>
  inputIcon?: HTMLOrSVGElement
  hasTooltip?: boolean
  focusError?: boolean
  tooltipContent?: string
  wrapperClassName?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TableInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .pq-input {
    width: 100%;
    outline: 0;
    height: 20px;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;

    &::placeholder {
      color: #8a91a5;
      opacity: 0.5;
    }

    /* width: 105px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.-disabled-text-style {
      color: #0004;
    }
  }
`

const LineInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .pq-input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: solid 2px ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    outline: 0;
    padding-left: 22px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;

    ${(props: InputProps) =>
      props.noFocus
        ? ''
        : css`
            &:focus {
              border: solid 2px ${props.color || '#3f6db4'};
            }
          `}
    ${(props: InputProps) =>
      props.isPassword
        ? css`
            font-size: 20px;
            letter-spacing: 8px;
            padding-right: 40px;
          `
        : ''}
    ${(props: InputProps) =>
      props.isSearchBox
        ? css`
            padding-left: 64px;
          `
        : ''}
  }

  .pq-input-board {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: #ffffff;
    &.-disabled {
      opacity: 0.5;
    }
  }
  .pq-input-title {
    position: absolute;
    margin-left: 1em;
    margin-top: -0.625em;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 8px;
    opacity: 1 !important;
  }
  .pq-drag-indicator {
    display: flex;
    margin-top: -36px;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    box-sizing: border-box;
    padding-right: 12px;
  }
  .pq-input-drag {
    position: absolute;
    width: 8px;
    height: 24px;
    border-top: solid 0px transparent;
    border-bottom: solid 0px transparent;
    border-left: solid 2px rgba(203, 208, 223, 0.3);
    border-right: solid 2px rgba(203, 208, 223, 0.3);
    cursor: pointer;
  }
  .pq-search-indicator {
    display: flex;
    margin-top: -36px;
    align-items: center;
    justify-content: flex-start;
    margin-left: 24px;
    height: 24px;
  }
`
// @todo refactor input
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, forwardedRef) => {
    const {
      className,
      caption,
      placeholder,
      refRegister,
      errorMessage,
      isTableInput,
      disabled,
      noFocus,
      pqInputBoardCss,
      errorBoard,
      errorBoardCss,
      isPassword,
      isDraggable,
      isSearchBox,
      showPlaceholderMode,
      showDragbar,
      value,
      hasTooltip,
      tooltipContent,
      autoComplete = 'off',
      inputIcon,
      type,
      onFocus,
      name,
      focusError,
      wrapperClassName = '',
      errors,
      ...rest
    } = props

    const classes = classNames('pq-input', className || '')
    const borderColor =
      (errors && !!errors[name]) || !!errorMessage || focusError
        ? 'rgba(255, 105, 109, 0.5)'
        : ''
    const boardClasses = classNames(
      'pq-input-board',
      (disabled && '-disabled') || '',
    )
    const titleClasses = classNames(
      'pq-input-title',
      (disabled && '-disabled') || '',
    )

    // @todo check if this is needed?
    // should probably be removed
    let ctxRegister
    const formCtx = useFormContext()
    if (formCtx) {
      ctxRegister = formCtx.register(name)
    }

    // show / hide password
    const [showPassword, setShowPassword] = useState(false)
    const [isFocused, setFocused] = useState(false)
    const [isOverflowed, overflowRef] = useOverflowDetect()

    const ref = useCombinedRefs(
      refRegister || ctxRegister,
      overflowRef,
      forwardedRef,
    )

    const placeholderText = () => {
      if (showPlaceholderMode) {
        return isFocused ? '' : placeholder
      }
      return placeholder
    }

    const renderDefaultInput = () => {
      const inputControl = (
        <input
          data-cy="form-input"
          className={classes}
          css={
            hasTooltip
              ? css`
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                `
              : css``
          }
          placeholder={placeholderText()}
          ref={ref}
          disabled={disabled}
          type={isPassword && !showPassword ? 'password' : type || ''}
          draggable={isDraggable}
          onFocus={(e) => {
            if (onFocus) {
              onFocus(e)
            }
            setFocused(true)
            e.currentTarget.select()
          }}
          defaultValue={rest?.defaultValue}
          name={name}
          onBlur={() => {
            setFocused(false)
          }}
          value={value}
          autoComplete={autoComplete}
          {...rest}
        />
      )

      if (hasTooltip) {
        return (
          <Tooltip
            content={tooltipContent}
            showTooltip={isOverflowed}
            maxWidth="147px"
          >
            {inputControl}
          </Tooltip>
        )
      }

      return inputControl
    }

    return isTableInput ? (
      <TableInputWrapper className={wrapperClassName} css={pqInputBoardCss}>
        <Tooltip
          content={value && value}
          showTooltip={isOverflowed}
          maxWidth="147px"
        >
          <input
            className={classes}
            name={name}
            ref={ref}
            data-cy="form-input"
            placeholder={placeholderText()}
            disabled={disabled}
            onFocus={(e) => {
              if (onFocus) {
                onFocus(e)
              }
              setFocused(true)
            }}
            onBlur={() => {
              setFocused(false)
            }}
            defaultValue={rest?.defaultValue}
            value={value}
            autoComplete="off"
            {...rest}
          />
        </Tooltip>
        <FormElementError
          errors={errors}
          name={name}
          showErrorBoard={errorBoard}
          errorMessage={errorMessage}
          css={errorBoardCss}
        />
      </TableInputWrapper>
    ) : (
      <LineInputWrapper
        className={wrapperClassName}
        css={pqInputBoardCss}
        color={borderColor}
        noFocus={noFocus}
        isPassword={isPassword && !showPassword}
        isSearchBox={isSearchBox}
        data-cy="sidebar-header"
      >
        <div className={boardClasses}>
          {caption && <span className={titleClasses}>{caption} </span>}
          {/* eslint-disable-next-line react/void-dom-elements-no-children */}
          {renderDefaultInput()}
          {isPassword && (
            <div className="pq-drag-indicator">
              <SvgVisibility
                css={css`
                  path:last-child {
                    ${showPassword ? `fill: ${styleVars.primaryBlue};` : ''}
                  }
                `}
                className="pq-hover-pointer"
                onMouseDown={() => setShowPassword(true)}
                onMouseUp={() => setShowPassword(false)}
              />
            </div>
          )}
          {(showDragbar || isDraggable) && (
            <div className="pq-drag-indicator">
              <div className="pq-input-drag" />
            </div>
          )}
          {inputIcon && (
            <div className="pq-drag-indicator">
              <>{inputIcon}</>
            </div>
          )}
          {isSearchBox && (
            <div className="pq-search-indicator">
              <img src="/static/svg/icons/search.svg" alt="search" />
            </div>
          )}
        </div>
        <FormElementError
          errors={errors}
          name={name}
          showErrorBoard={errorBoard}
          errorMessage={errorMessage}
          css={errorBoardCss}
        />
      </LineInputWrapper>
    )
  },
)
