import { css } from '@emotion/react'

const customSingleValue = (props, selectRef) => {
  const { data, getStyles, inputMarginLeft } = props

  return (
    <div
      ref={selectRef}
      style={getStyles('singleValue', props)}
      className="pq-select__single-value"
      css={css`
        margin-left: ${inputMarginLeft};
      `}
    >
      {data?.icon && (
        <span
          className={`pq-select__single-value-icon pq-icon -${data.icon}`}
        />
      )}
      <span>{data?.label}</span>
    </div>
  )
}

export default customSingleValue
