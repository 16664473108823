// src
import Tooltip from '@common/components/tooltip'
import customSingleValue from './single-value-custom'

const toolTipSingleValue = (props, selectRef = null, isOverflowed = null) => {
  const { data, getStyles, isDisabled } = props

  return (
    <Tooltip
      content={data?.label}
      isDisabled={isDisabled}
      showTooltip={true || isOverflowed}
      showArrowNubOntop
      placement="top-start"
      // pass along single value props to tooltip
      style={{
        ...getStyles('singleValue', props),
        marginLeft: 'unset',
        display: 'flex',
      }}
    >
      {customSingleValue(props, selectRef)}
    </Tooltip>
  )
}

export default toolTipSingleValue
