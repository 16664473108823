import * as React from 'react'

import {
  GroupBase,
  createFilter,
  Props as ReactSelectProps,
} from 'react-select'

import AsyncSelect from 'react-select/async'
import { IFilterConfig } from '../select/index'
import { classNames } from '@common/utils/helpers'
import styled from '@emotion/styled'
import { useIntl } from '@palqee/intl'

interface SelectProps {
  className?: string | undefined
  caption?: string
  loadOptions?: any
  name?: string | undefined
  placeholder?: string | undefined
  errorMessage?: string | undefined
  errorBoard?: boolean | undefined
}

export const AsyncMultiSelect: React.FC<
  React.PropsWithChildren<
    SelectProps & Partial<ReactSelectProps<any, boolean, GroupBase<any>>>
  >
> = (props) => {
  const intl = useIntl()
  const {
    className,
    caption,
    name,
    placeholder = intl.formatMessage({
      id: 'SELECT',
      defaultMessage: 'Select',
    }),
    errorMessage,
    errorBoard,
    ...rest
  } = props
  const classes = classNames('pq-multiselect', className || '')
  const boardColor = errorMessage ? 'rgba(255, 105, 109, 0.5)' : ''
  const filterConfig: IFilterConfig = {
    ignoreCase: true,
    matchFrom: 'start',
  }

  return (
    <MultiSelectWrapper color={boardColor}>
      <div
        data-cy="form-async-multiselect"
        className="pq-multiselect-input-board"
      >
        <span className="pq-multiselect-input-title"> {`${caption}`}</span>
        <AsyncSelect
          className={classes}
          classNamePrefix="pq-multiselect"
          {...rest}
          name={name}
          isMulti
          instanceId={name}
          placeholder={placeholder}
          filterOption={createFilter(filterConfig)}
          cacheOptions
          defaultOptions
        />
      </div>
      {errorBoard && (
        <div
          data-cy="form-async-multiselect-error-msg"
          className="pq-error-message"
        >
          {errorMessage}
        </div>
      )}
    </MultiSelectWrapper>
  )
}

const MultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  .pq-multiselect-input-board {
    border-radius: 8px;
    border: solid 0px rgba(203, 208, 223, 0.3);
  }

  .pq-multiselect-input-title {
    position: absolute;
    margin-left: 16px;
    margin-top: -10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  .pq-multiselect {
    width: 100%;
    border-radius: 8px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
    box-sizing: border-box;
  }
  .pq-multiselect__control {
    min-height: 48px;
    border: solid 2px;
    border-radius: 8px;
    color: #0d1331;
    border-color: ${(props) => props.color || 'rgba(203, 208, 223, 0.3)'};
    background-color: white;
    box-shadow: none;
    flex-wrap: nowrap;
    cursor: text;

    &:focused {
      border: solid 2px ${(props) => props.color || '#3f6db4'};
    }
    &:hover {
      border: solid 2px rgba(203, 208, 223, 0.3);
    }
  }
  .pq-multiselect__control--is-focused {
    border: solid 2px #3f6db4;
    &:focused {
      border: solid 2px ${(props) => props.color || '#3f6db4'};
    }
    &:hover {
      border: solid 2px ${(props) => props.color || '#3f6db4'};
    }
  }

  .pq-multiselect__control--menu-is-focused {
    border: solid 2px ${(props) => props.color || '#3f6db4'};
  }
  .pq-multiselect__control--menu-is-open {
    border: solid 2px ${(props) => props.color || '#3f6db4'};
  }
  .pq-multiselect__option--is-selected {
    color: black;
    background-color: white;
  }

  .pq-multiselect__option--is-focused {
    color: black;
    background-color: #eff3f9;
  }

  .pq-multiselect__value-container {
    margin-left: 12px;
  }
  .pq-multiselect__menu {
    z-index: 2;
    margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4);
  }
  .pq-multiselect__option {
    padding-left: 24px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
    cursor: pointer;
  }
  .pq-multiselect__menu-list {
    max-height: 200px;
  }
  .pq-multiselect__multi-value {
    background-color: rgba(203, 208, 223, 0.15);
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #1a3154;
    cursor: pointer;
  }
  .pq-multiselect__indicator-separator {
    display: none;
  }
  .pq-multiselect__indicator {
    display: none;
  }
`
