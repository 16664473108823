import 'react-datepicker/dist/react-datepicker.css'

import DatePicker, {
  CalendarContainer,
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker'
import React, { forwardRef, useCallback, useEffect } from 'react'

import { Message } from 'react-hook-form'
import SvgLeftArrowBg from '@static/svg/icons/arrow-left-circle-bg.svg'
import SvgRightArrowBg from '@static/svg/icons/arrow-right-circle-bg.svg'
import SvgRightDoubleArrow from '@static/svg/icons/double-arrow-right-circle-bg.svg'
import { classNames } from '@common/utils/helpers'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { useCombinedRefs } from '@common/utils/use-hooks'
import { usePqIntl } from '@palqee/intl'

export interface PqDatePickerProps extends ReactDatePickerProps {
  id?: string
  name?: string
  className?: string
  caption?: string
  placeholder?: string
  errorBoard?: boolean | undefined
  secondary?: boolean | undefined
  backgroundColor?: string
  onChange: (e: any) => void
  showYearDropdown?: boolean
  hasError?: boolean
  errorMessage?: Message
  refRegister?: any
  setValue?: any
  value?: string
  defaultValue?: string
  inputBoardcss?: any
}

const CustomCalendarContainer = (
  { children = null, ...rest },
  isDateSelected: boolean,
) => {
  return (
    <CalendarWrapper isDateSelected={isDateSelected} {...rest}>
      {children && <div style={{ position: 'relative' }}>{children}</div>}
    </CalendarWrapper>
  )
}

const CustomCalendarHeader = (
  {
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
  },
  { locale },
) => {
  return (
    <CalendarHeaderWrapper>
      <button
        type="button"
        onClick={() => changeYear(dayjs(date).subtract(1, 'year').get('year'))}
      >
        {/*
           @todo get better designs of svg double arrows
           instead of using css
        */}
        <SvgRightDoubleArrow
          width="24px"
          height="24px"
          style={{ transform: 'rotate(180deg)', marginBottom: '0.5em' }}
        />
      </button>
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <SvgLeftArrowBg width="24px" height="24px" />
      </button>
      <span>{dayjs(date).locale(locale).format('MMMM YYYY')}</span>
      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <SvgRightArrowBg width="24px" height="24px" />
      </button>
      <button
        type="button"
        onClick={() => changeYear(dayjs(date).add(1, 'year').get('year'))}
      >
        <SvgRightDoubleArrow
          width="24px"
          height="24px"
          style={{ marginTop: '0.5em' }}
        />
      </button>
    </CalendarHeaderWrapper>
  )
}

const CustomCalendarDay = (day) => (
  <CalendarDayWrapper>{day}</CalendarDayWrapper>
)

export const PqDatePicker: React.FC<PqDatePickerProps> = forwardRef(
  (props, forwardedRef) => {
    const {
      errorBoard,
      errorMessage,
      caption,
      refRegister,
      hasError,
      className,
      value = null,
      showYearDropdown = false,
      ...rest
    } = props

    const combinedRefs = useCombinedRefs(refRegister, forwardedRef)

    const boardClasses = classNames('pq-input-board', className || '')
    const borderColor =
      hasError || errorMessage ? 'rgba(255, 105, 109, 0.5)' : ''
    const [localeState] = usePqIntl()

    const setLocaleForDateFns = useCallback((locale: string) => {
      if (locale === 'en') {
        return 'en-US'
      }
      return locale
    }, [])

    useEffect(() => {
      const loadDateFnsLocale = async () => {
        const loadLocale = () =>
          import(
            `date-fns/locale/${setLocaleForDateFns(
              localeState.locale,
            )}/index.js`
          )
        const locale = await loadLocale()
        registerLocale(localeState.locale, locale)
      }

      const loadDayJsLocale = async () => {
        const loadLocale = () => import(`dayjs/locale/${localeState.locale}.js`)
        await loadLocale()
        dayjs().locale(localeState.locale)
      }

      if (localeState.locale) {
        loadDateFnsLocale()
        loadDayJsLocale()
      }
    }, [localeState.locale, setLocaleForDateFns])

    // calendar is using date-fns
    // which has a different formatting
    // lowercase the dd
    const dateFormat = localeState.dateFormat.replace('DD', 'dd')

    const setSelected = () => {
      return !!value ? new Date(value) : null
    }

    return (
      <Wrapper color={borderColor}>
        <div data-cy="form-pq-datepicker" className={boardClasses}>
          {caption && <span className="pq-input-title">{caption}</span>}
          <DatePickerWrapper
            ref={combinedRefs}
            dateFormat={`${dateFormat}/yyyy`}
            selected={setSelected()}
            locale={localeState.locale}
            showYearDropdown={showYearDropdown}
            showPopperArrow={false}
            dropdownMode="select"
            popperProps={{
              strategy: 'fixed', // use this to make the popper position: fixed
            }}
            calendarContainer={(calendarContainerProps) => {
              return CustomCalendarContainer(calendarContainerProps, !!value)
            }}
            renderCustomHeader={(customHeaderProps) => {
              return CustomCalendarHeader(customHeaderProps, localeState)
            }}
            renderDayContents={CustomCalendarDay}
            calendarClassName="pq-datepicker-calendar"
            {...rest}
          />
        </div>
        {errorBoard && (
          <div
            data-cy="form-async-pairselect-error-msg"
            className="pq-error-message"
          >
            {errorMessage}
          </div>
        )}
      </Wrapper>
    )
  },
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container {
    position: unset;
  }
  .react-datepicker-popper {
    z-index: 99999 !important;
  }
`

const CalendarDayWrapper = styled.span``

const CalendarHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 25px;

  span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #0d1331;
  }
`

const CalendarWrapper = styled(CalendarContainer)<{ isDateSelected: boolean }>`
  width: 302px;
  height: 326px;
  margin: 16px 0 0;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4);
  background-color: #ffffff;
  border: unset;
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a3a3a;

  .react-datepicker__month-container {
    float: unset;

    .react-datepicker__header {
      background-color: unset;
      border-bottom: unset;
      border-top-left-radius: unset;
      padding-top: 16px;

      .react-datepicker__day-name {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        color: #8a91a5;
      }
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 2.1rem;
    line-height: 1.9rem;
    font-family: Poppins;
    letter-spacing: normal;
    font-stretch: normal;
    font-style: normal;
    margin: 0.1rem;
  }

  .react-datepicker__day {
    font-size: 14px;
    font-weight: 500;
    color: #3a3a3a;
    border-radius: 1rem;
    background-color: #fafafa;

    &:hover {
      background-color: #eff3f9;
    }
  }

  .react-datepicker__day--selected {
    color: #ffffff;
    background-color: #3f6db4;
    border-radius: 1rem;
  }

  .react-datepicker__day--today {
    background-color: ${(props) => (props.isDateSelected ? 'none' : '#3f6db4')};
    color: ${({ isDateSelected }) => (isDateSelected ? '#3a3a3a' : '#ffffff')};
    border-radius: 1rem;
  }

  .react-datepicker__day--disabled {
    color: #ccc;
  }

  .react-datepicker__day--outside-month {
    color: #cbd0df;
  }
`

const DatePickerWrapper = styled(DatePicker)`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: solid 2px rgba(203, 208, 223, 0.3);
  outline: 0;
  padding-left: 22px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: #0d1331;

  :focus {
    outline: 0;
    border: solid 2px #3f6db4;
  }
`
