import { gray50 } from '@common/styles/constants'
import { css } from '@emotion/react'

export const SvgCss = css`
  & path:last-child {
    fill: ${gray50};
  }
`

export const InputCss = css`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  position: relative;
  height: 42px;
  outline: 0;
  text-align: center;
  width: 100%;
  max-width: 30px;
  border-bottom: solid 2px rgba(203, 208, 223, 0.3);
  border-top: solid 2px rgba(203, 208, 223, 0.3);
`

export const ButtonCss = css`
  border: solid 2px rgba(203, 208, 223, 0.3);

  button {
    background-color: inherit !important;
  }
`
