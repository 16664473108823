import { InputCss } from './styles'
import {
  ComponentProps,
  Dispatch,
  RefObject,
  SetStateAction,
  forwardRef,
  useState,
} from 'react'

const Input = forwardRef(
  (
    props: ComponentProps<'input'> & {
      inputValue: string
      setInputValue: Dispatch<SetStateAction<string>>
    },
    inputRef: RefObject<HTMLInputElement>,
  ) => {
    const {
      inputValue: controlledInputValue,
      setInputValue: controlledSetInputValue,
      ...restProps
    } = props

    const [uncontrolledInputValue, uncontrolledSetInputValue] = useState(null)

    const inputValue = controlledInputValue ?? uncontrolledInputValue
    const setInputValue = controlledSetInputValue ?? uncontrolledSetInputValue

    return (
      <input
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        css={InputCss}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          if (inputRef.current) inputRef?.current?.focus()
        }}
        onChange={(e) => {
          e.stopPropagation()
          e.preventDefault()
          if (e.target.value >= props.min) {
            const newValue = e.target.value.replace(/\D/g, '') // Remove non-numeric characters
            setInputValue(newValue)
          }
        }}
        ref={inputRef}
        onBlur={(e) => {
          e.stopPropagation()
          setInputValue(inputRef.current.value)
        }}
        value={inputValue}
        {...restProps}
      />
    )
  },
)

export { Input }
