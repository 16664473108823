import * as styleVars from '@common/styles/constants'

import { PqSelectProps } from './types'
import styled from '@emotion/styled'

export const SelectWrapper = styled.div<PqSelectProps>`
  &.pq-select__wrapper {
    display: ${(props) => (props.isInline ? 'inline' : 'block')};
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .pq-select-input-board {
    ${(props) => (props.isInline ? 'display: inline-block;' : '')}
    border-radius: 8px;
    border: solid 0px rgba(203, 208, 223, 0.3);
    &.-disabled {
      opacity: 0.5;
    }
  }

  .pq-select-input-title {
    position: absolute;
    margin-left: 16px;
    margin-top: -10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
    &.-disabled {
      & * {
        opacity: 0.5;
      }
    }
  }

  .pq-select {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: transparent;
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
  }
  .pq-select--is-disabled {
    border: solid 2px rgba(203, 208, 223, 0.3);
  }
  .pq-select-container {
    width: 100%;
    border-radius: ${(props) => props.style.borderRadius || '8px'};
    border: solid 2px transparent;
    font-family: Poppins;
    font-size: 13px;
    line-height: 1.54;
    color: #0d1331;
    box-sizing: border-box;
  }
  .pq-select__value-container {
    margin-left: ${(props: any) => props.inputMarginLeft || '12px'};
    input {
      opacity: 1 !important;
    }
  }

  // implemented this to not set color red on
  // nested select input in menu
  .pq-select-input-board:not(.--nested) .pq-select__control {
    border-color: rgba(203, 208, 223, 0.3);
  }

  // select borders
  .pq-select-input-board.-error .pq-select__control {
    border-color: ${styleVars.controlErrorRed};
  }

  .pq-select-input-board:not(.-error) .pq-select__control--is-focused {
    ${(props) =>
      props.readOnly !== true ? `border-color: ${styleVars.primaryBlue}` : ''};
    &:hover {
      ${(props) =>
        props.readOnly !== true
          ? `border-color: ${styleVars.primaryBlue}`
          : ''};
    }
  }

  .pq-select__control:not(.--is-focused) {
    border: solid 2px rgba(203, 208, 223, 0.3);
  }

  .pq-select__control--is-disabled {
    opacity: 0.5;
    background-color: white;
    border: solid 2px rgba(203, 208, 223, 0.3);
  }
  .pq-select__control--menu-is-focused {
    border: solid 1px ${(props) => props.color || '#3f6db4'};
  }
  .pq-select__control--menu-is-open {
    border: solid 1px #3f6db4;
  }
  .pq-select__option {
    padding-left: 24px;
    border: solid 0px black;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
    display: flex;
    // padding-top: 12px;

    padding: 10px;
    box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3);
    align-items: center;
    cursor: pointer;

    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;

    &:hover {
      color: #3f6db4;
      .pq-icon {
        filter: grayscale(0);
      }
    }
    .pq-icon {
      filter: grayscale(1);
    }

    &:last-of-type {
      box-shadow: none;
    }
  }
  .pq-select__option--is-selected {
    color: #3f6db4;
    background-color: white;
  }

  .pq-select__option--is-focused {
    color: #3f6db4;
    background-color: #eff3f9;
  }

  .pq-select__menu {
    // width: 100%;
    z-index: 10000;
    // margin-top: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4);
  }
  .pq-select__menu-list {
    // max-height: 150px;
  }
  .pq-select__indicator-separator {
    display: none;
  }
  .pq-select__dropdown-indicator {
    padding-right: 12px;
  }
  .pq-select__single-value {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
  .pq-select__single-value-icon {
    margin-right: 16px;
    filter: grayscale(1);
  }

  .pq-select__option-icon {
    margin-right: 16px;
  }
`

export const TableSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const commonStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  menuList: (styles) => ({
    ...styles,
    margin: '8px 0',
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '130px',
  }),
  option: (styles) => {
    return {
      ...styles,
      padding: '12px 10px 10px 10px',
      height: '42px',
      width: '152px',
      border: 'solid 0px rgba(203, 208, 223, 0.3)',
      boxShadow: 'inset 0 -1px 0 0 rgba(203, 208, 223, 0.3)',
      '&:first-of-type': {
        marginTop: '0',
      },
      '&:last-of-type': {
        boxShadow: 'none',
      },
      position: 'relative',
      color: `${styleVars.darkBlack} !important`,
      backgroundColor: 'transparent',
      '&:focus': {
        backgroundColor: 'none',
      },
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.54',
      letterSpacing: 'normal',
    }
  },
}
