import * as React from 'react'
import styled from '@emotion/styled'

interface StarProps extends React.HTMLAttributes<HTMLInputElement> {
  color?: any
  readOnly?: boolean
  selected?: boolean
}
type Star = React.FC<React.PropsWithChildren<StarProps>>

const StarWrapper = styled.span``

export const Star: Star = ({ color, selected, readOnly, ...rest }) => {
  return (
    <StarWrapper color={color} {...rest}>
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        cursor={readOnly ? 'normal' : 'pointer'}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-811.000000, -1410.000000)">
            <g transform="translate(295.000000, 296.000000)">
              <g transform="translate(24.000000, 956.000000)">
                <g transform="translate(412.000000, 120.000000)">
                  <g transform="translate(72.000000, 30.000000)">
                    <g>
                      <g>
                        <polygon id="Path" points="0 0 48 0 48 48 0 48" />
                        <polygon id="Path" points="0 0 48 0 48 48 0 48" />
                      </g>
                      <g
                        transform="translate(8.000000, 8.000000)"
                        fill={selected || readOnly ? color : '#cbd0df'}
                        fillRule="nonzero"
                      >
                        <path d="M16.5,26.7990344 L24.5022603,31.7098266 C25.9677344,32.6098124 27.761012,31.2793986 27.3753609,29.5968164 L25.2542799,20.3621792 L32.3309775,14.1405381 C33.6229087,13.0057734 32.9287367,10.8536334 31.2318719,10.716679 L21.9183979,9.91451773 L18.273995,1.18856823 C17.6183882,-0.396189411 15.3816118,-0.396189411 14.726005,1.18856823 L11.0816021,9.89495282 L1.76812811,10.6971141 C0.0712632826,10.8340685 -0.622908691,12.9862085 0.669022482,14.1209732 L7.74572011,20.3426143 L5.62463907,29.5772515 C5.23898798,31.2598337 7.03226558,32.5902475 8.49773974,31.6902617 L16.5,26.7990344 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </StarWrapper>
  )
}
