import * as React from 'react'
import styled from '@emotion/styled'
import { classNames } from '@common/utils/helpers'

interface InputGroup extends React.FC<React.HTMLAttributes<HTMLElement>> {
  Append: any
}
interface Append extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
}

type AppendTypes = React.FC<React.PropsWithChildren<Append>>

const InputGroupWrapper = styled.div`
  &.pq-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    .pq-input.pq-form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
      -ms-flex: 1 1 0%;
      flex: 1 1 0%;
      min-width: 0;
      margin-bottom: 0;
      width: 100%;
      display: block;
    }
  }
`
const AppendWrapper = styled.div`
  &.pq-input-group-append {
    margin-left: -5px;

    .pq-button {
      background-color: #ffffff;
      border: solid 2px rgba(203, 208, 223, 0.3);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0;

      .pq-button__content {
        justify-content: center !important;
      }
    }

    .pq-input-group-text {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 12px 0;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8a91a5;
      text-align: center;
      white-space: nowrap;
      background-color: #ffffff;
      border: solid 2px rgba(203, 208, 223, 0.3);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`

const Append: AppendTypes = (props) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-input-group-append', className || '')

  return (
    <AppendWrapper className={classes} {...rest}>
      <span className="pq-input-group-text">{children}</span>
    </AppendWrapper>
  )
}

const InputGroup: InputGroup = (props) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-input-group', className || '')

  return (
    <InputGroupWrapper className={classes} {...rest}>
      {children}
    </InputGroupWrapper>
  )
}

InputGroup.Append = Append

export { InputGroup }
